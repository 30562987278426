import React from "react"
import { navigate } from "gatsby"

// Maintaining this page in case of old links sent out with marketing material
const Quote = () => {
  if (typeof window !== `undefined`) {
    window.location.href = `/tenants/app/tool-a/signup${window.location.search}`
  }

  return <div />
}

export default Quote
